import { Helmet } from "react-helmet"

export function PageHelmet({
  helmet = "Web app",
  name,
  content,
}: {
  helmet?: string
  name?: string
  content?: string
}) {
  return (
    <Helmet>
      <title>{helmet} | Minut</title>
      {!!name && !!content && <meta name={name} content={content}></meta>}
    </Helmet>
  )
}
